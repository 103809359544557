import React, { useEffect, useState } from "react";
import { Img, Text } from "components";
import BinanceApi from "lib/api/binanceApi";
import useProductStore from "hooks/zustand/product/useProductStore";
import { useNavigate } from "react-router-dom";

export default function TickerTableWidget({
  className = "",
  isReload,
  setIsReload,
}) {
  const [tickerInfo, setTickerInfo] = useState();
  const productStore = useProductStore();
  const [productInfo, setProductInfo] = useState();
  const navigate = useNavigate();

  const getProductInfo = async() =>{
    const res = await productStore.getAllProduct();
    if (res) {
      setProductInfo(res);
    }
  }

  const getTickerData = async () => {
    let symbols = [];
    if (productInfo?.length > 0) {
      productInfo?.map((item) => {
        symbols.push(`"${item.symbol}"`);
      });
      const res = await BinanceApi(`ticker/24hr?symbols=[${symbols}]`);
      if (res.status === 200) {
        setTickerInfo(res.data);
      }
    }
  };

  useEffect(() => {
    if (isReload) {
      setIsReload(false);
      getProductInfo();
    }
  }, [isReload]);

  useEffect(() => {
    if (productInfo?.length > 0) {
      getTickerData(); // This will now run after productInfo is set
    }
  }, [productInfo]);

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="flex flex-row w-full justify-center">
        <Text className="w-1/3 text-center !font-bold">Trading Pair</Text>
        <Text className="w-1/3 text-center !font-bold">Latest Price</Text>
        <Text className="w-1/3 text-center !font-bold">
          24h Change Percentage
        </Text>
      </div>
      { tickerInfo && productInfo &&
        productInfo?.map((item) => {
          const tickerItem = tickerInfo?.filter(
            (itemSymbol) => itemSymbol.symbol === item.symbol
          )[0];
          return (
            <a
            onClick={ () => {
              navigate("/dashboard/contractOrder/"+item.symbol.toLowerCase());
            }}
            className="hover:cursor-pointer flex w-[95%] justify-center items-center"
          >
            <div
              className={`flex flex-row !justify-center !items-center w-[95%] p-5 border-b-4 mt-2 border-[0.1rem] rounded-lg bg-white ${className}`}
            >
              <div className="flex flex-row w-1/3 text-center justify-left items-center gap-3">
              <Img className='w-[30%]' src={`/images/coinIcons/${item.coinId.toLowerCase()}.jpg`} alt={item.coidId} />
              <Text >{item.coinId}</Text>
              </div>
              
              <Text
                className={` w-1/3 text-center ${
                  parseFloat(tickerItem?.priceChangePercent) < 0
                    ? "!text-red-700"
                    : "!text-green-700"
                }`}
              >
                {parseFloat(tickerItem?.lastPrice).toFixed(4) ?? 0}
              </Text>
              <Text
                className={` w-1/3 text-center ${
                  parseFloat(tickerItem?.priceChangePercent) < 0
                    ? "!text-red-700"
                    : "!text-green-700"
                }`}
              >
                {parseFloat(tickerItem?.priceChangePercent).toFixed(2) ?? 0}%
              </Text>
            </div>
            </a>
          );
        })}
    </div>
  );
}
