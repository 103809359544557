import React, { useEffect, useState } from "react";
import { Text } from "components";
import BinanceApi from "lib/api/binanceApi";

export default function TickerWidget({
  symbolName,
  symbol,
  className = "",
  isReload,
  setIsReload,
}) {
  const [tickerInfo, setTickerInfo] = useState();
  const getTickerData = async () => {
    const res = await BinanceApi(`ticker/24hr?symbol=${symbol}`);
    if (res.status === 200) {
      setTickerInfo(res.data);
    }
  };

  useEffect(() => {
    if (isReload) {
      getTickerData();
      setIsReload(false);
    }
  }, [isReload]);
  return (
    <div className={`flex flex-col justify-center items-center ${className}`}>
      <Text>{symbolName}</Text>
      <Text
        className={` ${
          parseFloat(tickerInfo?.priceChangePercent) < 0
            ? "!text-red-700"
            : "!text-green-700"
        }`}
      >
        {parseFloat(tickerInfo?.lastPrice).toFixed(2) ?? 0}
      </Text>
      <Text
        className={` ${
          parseFloat(tickerInfo?.priceChangePercent) < 0
            ? "!text-red-700"
            : "!text-green-700"
        }`}
      >
        {parseFloat(tickerInfo?.priceChangePercent).toFixed(2) ?? 0}%
      </Text>
    </div>
  );
}
