import React from "react";
import { Button, Text } from "components";
import {
  MdHome,
  MdOutlinePlaylistAddCheck,
  MdOutlineSwipe,
  MdChat,
  MdOutlinePerson,
} from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      {/* footer section */}
      <div className="fixed w-full h-auto z-10 left-0  bottom-0 bg-white opacity-80">
        <div className="flex flex-row w-full justify-center ">
          <div className="flex flex-col w-1/4 items-center justify-center text-center  ">
            <Link to="/dashboard">
              <MdHome className="footer icon" />
              <Text className="!text-sm">Home</Text>
            </Link>
          </div>
          <div className="flex flex-col w-1/4 items-center text-center  ">
            <Link to="/dashboard/order-record">
              <MdOutlinePlaylistAddCheck className="footer icon" />
              <Text className="!text-sm">Orders</Text>
            </Link>
          </div>
          <div className="flex flex-col w-1/4 items-center text-center  ">
            {/* <Link to="/dashboard/order"> */}
              <GoGraph className="footer icon" />
              <Text className="!text-sm">Market</Text>
            {/* </Link> */}
          </div>
          <div className="flex flex-col w-1/4 items-center text-center ">
            <Link to="/dashboard/profile">
              <MdOutlinePerson className="footer icon" />
              <Text className="!text-sm">My</Text>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
