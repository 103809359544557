import React, { useEffect, useState } from "react";
import Layout from "components/member/Layout";
import { Button, Text } from "components";
import Carousel from "./Carousel";
import { MdAttachMoney, MdPayment, MdOutlineShare } from "react-icons/md";
import GainSection from "./GainSection";
import MemberLevelSection from "./MemberLevelSection";
import PartnerSection from "./PartnerSection";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import { toUSD } from "lib/currencyFormatter";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AcredittedSection from "./AcredittedSection";
import ServicesSection from "./ServicesSection";
import TickerWidgetSection from "./TickerWidgetSection";
import TickerTableSection from "./TickerTableSection";


export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const navigate = useNavigate();


  const initUserInfo = async () => {
    await userInfo.getData();
  };


  useEffect(() => {
    //if (userInfo.user._id === "") {
    initUserInfo();
  }, [])

  return (
    <Layout>
      <div className="container">
        <Carousel />
      </div>
      <div className="container flex justify-center  ">
        <div className="w-[90%]  border-0 rounded-lg bg-white p-3 z-10 mt-[-1.00rem]">
          <div className="flex flex-row w-full flex-grow">
            <div className="flex flex-col w-1/2 ">
              <Text className="!text-xl">Hello, </Text>
              <Text className="!text-2xl !font-semibold">
                {userInfo?.user?.fullName}
              </Text>
            </div>
            <div className="flex flex-col items-end w-full ">
              <Text className="text-3xl font-bold ">
                {toUSD(userInfo?.user?.wallet + userInfo?.user?.frozenAmount)}
              </Text>
              <Text className="text-base">My balance</Text>
            </div>
            {/* <div className="flex flex-col col-auto">
                <Button className="btn-round" size="">
                  <MdAttachMoney className="w-[30px] h-[30px]" />
                </Button>
              </div> */}
          </div>
        </div>
      </div>
              
      <ServicesSection />
      <TickerWidgetSection  />
      <TickerTableSection />
      
    </Layout>
  );
}
