import { memo, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalDefault = memo(
  ({ size = 'medium', open, setOpen, title, children }) => {
    const modalSize = () => {
      if (size === 'small') {
        return 'w-full max-w-full lg:max-w-md';
      }
      if (size === 'medium') {
        return 'w-full max-w-full lg:max-w-2xl';
      }
      if (size === 'large') {
        return 'w-full max-w-full lg:max-w-6xl';
      }
      if (size === 'extra-large') {
        return 'w-full max-w-full lg:max-w-7xl';
      }
      return 'w-full max-w-full lg:max-w-2xl';
    };

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0  bg-opacity-50 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-5'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${modalSize()}`}
                >
                  <div className='bg-white p-5 w-full'>
                    <div className='sm:flex sm:items-start w-full'>
                      <div className='mt-3 w-full'>
                        <Dialog.Title
                          as='h3'
                          className='font-semibold leading-6'
                        >
                          {title ? title : 'Detail'}
                        </Dialog.Title>
                        {children}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
);

export default ModalDefault;
