
import { WithGuard } from "middleware/route.guard";
import React , { lazy, Suspense } from "react";
import Dashboard from "../pages/MemberDashboard/Dashboard";
import { Route } from "react-router-dom";
import Loading from "components/Loading";

const Withdrawal = lazy(() => import("../pages/MemberDashboard/Withdrawal"));
const Profile = lazy(() => import("../pages/MemberDashboard/profile"));
const Languages = lazy(() => import("../pages/MemberDashboard/Language"));
const WithdrawalHistory = lazy(()=> import("../pages/MemberDashboard/WithdrawalHistory"));
const DepositHistory = lazy(() => import("../pages/MemberDashboard/Deposit"));
const AccountDetail = lazy(() => import('../pages/MemberDashboard/AccountDetail'));
const Email = lazy(() => import ("../pages/MemberDashboard/Email"));
const Password = lazy( ()=> import('../pages/MemberDashboard/Password'));
const WithdrawalPassword = lazy( ()=> import('../pages/MemberDashboard/WithdrawalPassword'));
const Order = lazy(()=>import('../pages/MemberDashboard/Order'));
const BankInformation = lazy(() => import("../pages/MemberDashboard/BankInformation"));
const Investment = lazy(()=> import('../pages/MemberDashboard/Investment'));
const OrderRecord = lazy(() => import('../pages/MemberDashboard/OrderRecord'));
const Support = lazy(() => import('../pages/MemberDashboard/Support'));
const Invitation = lazy(() => import('../pages/MemberDashboard/Invitation'));
const ContractOrder = lazy(() => import('../pages/MemberDashboard/ContractOrder'));




const DashboardGuard = WithGuard(Dashboard);
const ProfileGuard = WithGuard(Profile);
const LanguagesGuard = WithGuard(Languages); 
const WithdrawalGuard = WithGuard(Withdrawal);
const WithdrawalHistoryGuard = WithGuard(WithdrawalHistory);
const DepositHistoryGuard = WithGuard(DepositHistory);
const AccountDetailGuard = WithGuard(AccountDetail);
const EmailGuard = WithGuard(Email);
const PasswordGuard = WithGuard(Password);
const WithdrawalPasswordGuard = WithGuard(WithdrawalPassword);
const OrderGuard = WithGuard(Order);
const BankInformationGuard = WithGuard(BankInformation);
const InvestmentGuard = WithGuard(Investment);
const OrderRecordGuard = WithGuard(OrderRecord);
const SupportGuard = WithGuard(Support);
const InvitationGuard = WithGuard(Invitation);
const ContractOrderGuard = WithGuard(ContractOrder);

export default [
   <Route  path="dashboard" element={ <Suspense fallback={<Loading />}><DashboardGuard /></Suspense> } />,
    <Route path="/dashboard/profile" element={<Suspense fallback={<Loading />}><ProfileGuard /></Suspense>} />,
    <Route path="/dashboard/profile/language" element={<Suspense fallback={<Loading />}><LanguagesGuard /></Suspense>} />,
    <Route path="/dashboard/profile/change-password" element={<Suspense fallback={<Loading />}> <PasswordGuard /> </Suspense>} />,
    <Route path='/dashboard/profile/change-withdrawal-password' element={<Suspense fallback={<Loading />}> <WithdrawalPasswordGuard /> </Suspense>} />,
    <Route path='/dashboard/profile/withdrawal' element={<Suspense fallback={<Loading />}>< WithdrawalGuard /></Suspense> }  />,
    <Route path="/dashboard/profile/withdrawal-history" element={ <Suspense fallback={<Loading />}><WithdrawalHistoryGuard /></Suspense>  } />,
    <Route path='/dashboard/profile/recharge-record' element={ <Suspense fallback={<Loading />}><DepositHistoryGuard /> </Suspense> } />,
    <Route path='/dashboard/profile/account-detail' element={ <Suspense fallback={<Loading />}><AccountDetailGuard /> </Suspense> } />,
    <Route path="/dashboard/email" element={ <Suspense fallback={ <Loading />}> <EmailGuard /> </Suspense> } />,
    <Route path='/dashboard/change-password' element={<Suspense fallback={<Loading />}> <PasswordGuard /> </Suspense>} />,
    <Route path='/dashboard/order' element={<Suspense fallback={<Loading />}> <OrderGuard /> </Suspense>} />,
    <Route path='/dashboard/bank-information' element={<Suspense fallback={<Loading />}> <BankInformationGuard /> </Suspense>} />,
    <Route path="/dashboard/investment/bitcoin" element={<Suspense fallback={<Loading />} > <InvestmentGuard /> </Suspense>} />,
    <Route path="/dashboard/order-record" element={<Suspense fallback={<Loading />}><OrderRecordGuard /></Suspense>} />,
    <Route path="/dashboard/support" element={<Suspense fallback={<Loading />}><SupportGuard /></Suspense>} />,
    <Route path="/dashboard/invitation" element={<Suspense fallback = {<Loading />} ><InvitationGuard /></Suspense>} />,
    <Route path="/dashboard/contractOrder/:symbol" element={<Suspense fallback={<Loading />}> <ContractOrderGuard /> </Suspense> }/>
]
