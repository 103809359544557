export default function toRinggit(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-MY', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes('.')) {
    const parts = formattedAmount.split('.');
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    formattedAmount = integerPart + '.' + parts[1];
  }

  return 'RM' + formattedAmount;
}

export  function toUSD(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-MY', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes('.')) {
    const parts = formattedAmount.split('.');
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    formattedAmount = integerPart + '.' + parts[1];
  }

  return '$' + formattedAmount;
}

export function currency(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-MY', {
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
  });
  return formattedAmount;
}

export function numberToWords(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + ' billion';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + ' million';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + ' thousand';
  } else {
    return num.toString();
  }
}