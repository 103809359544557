import React from "react";
import { BiMoneyWithdraw } from "react-icons/bi";
import { PiHandDepositFill } from "react-icons/pi";
import { RiCustomerService2Line } from "react-icons/ri";
import { HiOutlineWallet } from "react-icons/hi2";
import { Text } from "components";
import { Link } from "react-router-dom";

export default function ServicesSection() {
  return (
    <div className="container flex justify-center items-center py-5">
      <div className="flex flex-col w-[90%] bg-white p-3 rounded-lg">
        <div className="flex flex-row w-full text-center">
          <div className="flex flex-col w-[25%] justify-center items-center">
            <Link to="/dashboard/support">
            <div className="flex flex-col justify-center items-center" >
              <BiMoneyWithdraw size={25} />
              <Text>Deposit</Text>
              </div>
            </Link>
          </div>
          <div className="flex flex-col w-[25%] justify-center items-center">
            <Link to="/dashboard/profile/withdrawal">
            <div className="flex flex-col justify-center items-center" >
              <PiHandDepositFill size={25} />
              <Text>Withdraw</Text>
              </div>
            </Link>
          </div>
          <div className="flex flex-col w-[25%] justify-center items-center">
            <Link to="/dashboard/support">
            <div className="flex flex-col justify-center items-center" >
              <RiCustomerService2Line size={25} />
              <Text>Customer Service</Text>
            </div>
            </Link>
          </div>
          <div className="flex flex-col w-[25%] justify-center items-center">
            <Link to="/dashboard/support">
            <div className="flex flex-col justify-center items-center" >
              <HiOutlineWallet size={25} />
              <Text>Recharge Online</Text>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
