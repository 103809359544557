import React, { useState, useEffect } from "react";
import TickerTableWidget from "./TradingView/TickerTableWidget";

export default function TickerTableSection({
}) {
  const [isReload, setIsReload] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsReload(true);
    }, parseInt(process.env.REACT_APP_HOMEPAGETICKER_INTERVAL) * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className="container flex justify-center items-center pt-3">
      <div className="flex flex-row w-[90%] justify-center items-center bg-white rounded-lg">
        <div className="flex flex-col w-full justify-center items-center pt-3">
          <TickerTableWidget isReload={isReload} setIsReload={setIsReload} />
        </div>
      </div>
    </div>
  );
}
