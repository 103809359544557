import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useBankStore from "hooks/zustand/bank/useBankStore";
import useLogStore from "hooks/zustand/admin-log/useLogStore";
import { Text } from "components";
import RHDropDownList from "components/RHForm/RHDropDownList";
// components
import { FormProvider, RHFInput, RHFInputAddon } from "components/RHForm";
import ButtonLp from "components/ButtonLp";
import useAuth from "hooks/zustand/useAuth";
import { useNavigate } from "react-router-dom";
import { handleRegisterResponse } from "../../lib/api/handleApiResponse";
import { enqueueSnackbar } from "notistack";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useHomeSlider from "hooks/zustand/useHomeSlider";
import { publicIpv4 } from "public-ip";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .matches(/^\d+$/, "Number only!")
    .min(10, "Minimum 10 digit numbers")
    .max(13, "Maximum 13 digit numbers"),
  password: yup
    .string()
    .min(6, "Minimum 6 digit characters!")
    .max(32, "Maximum 32 digit characters!")
    .required("Password is required"),
  transactionPassword: yup
    .string()
    .min(6, "Minimum 6 digit characters!")
    .max(32, "Maximum 32 digit characters!")
    .required("Withdrawal Password is required"),
  invitationCode: yup.string().required("Invitation Code is required"),
});

const defaultValues = {
  firstName: "",
  phoneNumber: "",
  password: "",
  invitationCode: "",
};

const SignUp = () => {
  const auth = useAuth();
  const bank = useBankStore();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const [bgColor, setBgColor] = useState(process.env.REACT_APP_LOGIN_BG_COLOR);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const selectLanguageREf = useRef(null);
  const [ipAddress, setIpAddress] = useState();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const initialGetBgImg = async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? "" !== "")
        setBgImg(resp.data.slider.bg_img);
    }
  };

  const getIpAddress = async () => {
    const publicIp = await publicIpv4();
    if (publicIp) {
      setIpAddress(publicIp);
    }
  };

  useEffect(() => {
    getIpAddress();
    i18next.changeLanguage(i18n.language);
    setSelectedLanguage(i18n.language);
    initialGetBgImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  function onError(error) {
    enqueueSnackbar(`${error}`, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }

  const onSubmit = async (data) => {
    data.lastLoginIp = ipAddress;
    const res = await auth.register(data);
    const memberId = data.phoneNumber;
    await logStore.createData({
      adminId: "",
      memberId: memberId,
      log: `${memberId} login unsuccessful due to suspended`,
      payload: data,
      action: "signup",
      module: "member",
      table: "users",
    });
    handleRegisterResponse(res, onError, setError, navigate, i18n.language);
  };

  const cmdLanguage_onChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18next.changeLanguage(selectedOption);
  };

  return (
    <>
      <div
        className="w-full h-screen relative"
        style={{
          //backgroundImage: `url('${bgImg}')`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 1,
          zIndex: "0",
        }}
      >
        <div
          className="w-full h-screen relative"
          style={{
            opacity: 1,
            backgroundColor: bgColor,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: "1",
          }}
        ></div>
      </div>

      <div className="w-full h-screen absolute top-0 flex flex-col items-center justify-center">
        {/* <div className='absolute top-0 right-0 p-5'>
          <RHDropDownList
            ref={selectLanguageREf}
            defaultValue={selectedLanguage}
            optionLists={[
              { label: "EN", value: "en" },
              { label: "MY", value: "my" },
              { label: "CN", value: "cn" },
              { label: "JA", value: "ja" }
            ]}
            onChange={cmdLanguage_onChange}
          />
        </div> */}
        <div>
          <img
            src={process.env.REACT_APP_LOGO_PATH}
            alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
            className="!w-32 lg:w-40 object-contain object-center"
          />
        </div>
        <div className="text-center">
          <Text className="p-5">
            The Poor and the middle class work for money, and the rich let money
            work for them
          </Text>
        </div>
        <div className="w-[60%] flex flex-col justify-center">
          {" "}
          {/* Add margin to create space between the image and form */}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-full flex-col gap-3">
              <RHFInput name="firstName" label={t("home.signup.name")} />
              <RHFInputAddon
                name="phoneNumber"
                label={t("home.signup.phone")}
                addon={process.env.REACT_APP_COUNTRYCODE}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <RHFInput
                name="password"
                label={t("home.signup.password")}
                type="password"
              />
              <RHFInput
                name="transactionPassword"
                label={t("home.signup.Withdrawal Password")}
                type="password"
              />
              <RHFInput
                name="invitationCode"
                label={t("home.signup.Invitation Code")}
              />
              <ButtonLp
                type="submit"
                className="w-40 mx-auto mt-5"
                loading={isSubmitting}
              >
                {t("home.signup.Submit")}
              </ButtonLp>
            </div>
          </FormProvider>
          <div className="flex justify-center pt-5">
            <Link to="/">
              <Text className="text-black-900 underline hover:text-white ">
                {t("home.signup.backtologin")}
              </Text>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
