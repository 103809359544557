import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function RHFInput({ name, label, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="flex flex-col gap-1">
                    {label && (
                        <label className="mb-1 text-sm font-medium text-gray-900">{label}</label>
                    )}
                    <input
                        {...field}
                        className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 !border-gray-800 h-10 w-full p-2.5"
                        autoComplete="off"
                        {...other}
                    />
                    {error?.message && (
                        <p className="text-red-500 text-xs">{error?.message}</p>
                    )}
                </div>
            )}
        />
    );
}
