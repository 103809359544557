import React, { useEffect, useState } from "react";
import TickerWidget from "./TradingView/TickerWidget";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";

export default function TickerWidgetSection({
  productInfo
}) {
  const navigate = useNavigate();
  const [isReload, setIsReload] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsReload(true);
    }, parseInt(process.env.REACT_APP_HOMEPAGETICKER_INTERVAL) * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className="container flex justify-center items-center">
      <div className="flex flex-col w-[90%] bg-white rounded-lg p-2">
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3">
          <a
            onClick={ () => {
              navigate("/dashboard/contractOrder/btcusdt");
            }}
            className="hover:cursor-pointer"
          >
            <TickerWidget
              symbol="BTCUSDT"
              symbolName="BTC"
              isReload={isReload}
              setIsReload={setIsReload}
            />
          </a>
          </div>
          <div className="flex flex-col w-1/3">
          <a
            onClick={ () => {
              navigate("/dashboard/contractOrder/ethusdt");
            }}
            className="hover:cursor-pointer"
          >
            <TickerWidget
              symbol="ETHUSDT"
              symbolName="ETH"
              isReload={isReload}
              setIsReload={setIsReload}
            />
          </a>
          </div>
          <div className="flex flex-col w-1/3">
          <a
            onClick={ () => {
              navigate("/dashboard/contractOrder/bnbusdt");
            }}
            className="hover:cursor-pointer"
          >
            <TickerWidget
              symbol="BNBUSDT"
              symbolName="BNB"
              isReload={isReload}
              setIsReload={setIsReload}
            />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
