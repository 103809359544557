import axios from "axios";

export default async function BinanceApi  (functionName, payload, functionType = 'GET') {
  try{
      let urlPath = "https://data-api.binance.vision/api/v3/" + functionName;
      let resp; 
      if (functionType === 'POST'){
          urlPath = encodeURI(urlPath);
          resp = await axios.post(urlPath, payload, {
              headers:{
                  'Content-Type': 'application/json',
              }
          });
      }
      else {
          resp = await axios.get(urlPath, {
              headers:{
                  'Content-Type': 'application/json'
              }
          });
      }
      return resp;
  }
  catch(error){
      console.log("Error Binance API : " +  error);
      return error.response.data;
  }   
}